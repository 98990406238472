import React from "react";
import { InventoryItem } from "@/types/inventory";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { EditInventoryDialog } from "./EditInventoryDialog";
import { supabase } from "@/lib/supabase";
import { Eye, Trash2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import Logger from "@/utils/logger";
import { useAuth } from '@/contexts/AuthContext';
import { useQuery } from "@tanstack/react-query";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface InventoryTableProps {
  inventory: InventoryItem[];
  onItemUpdated?: () => void;
}

interface StockInfo {
  totalQuantity: number;
  batchCount: number;
}

export function InventoryTable({ inventory, onItemUpdated }: InventoryTableProps) {
  const { toast } = useToast();
  const { canManageInventory } = useAuth();

  const { data: stockInfo = {} } = useQuery({
    queryKey: ['stock-info', inventory.map(i => i.sku)],
    queryFn: async () => {
      const stockData: Record<string, StockInfo> = {};
      
      // Get all batches in one query
      const { data: batches, error } = await supabase
        .from('batches')
        .select('sku, quantity, available_quantity, weight_kilos');
      
      if (error) throw error;
      
      // Calculate totals for each SKU
      inventory.forEach(item => {
        const itemBatches = batches.filter(b => b.sku === item.sku);
        const validBatches = itemBatches.map(batch => ({
          ...batch,
          effective_quantity: batch.available_quantity ?? 
            (item.is_weight_based ? batch.weight_kilos : batch.quantity) ?? 0
        }));
        
        stockData[item.sku] = {
          totalQuantity: validBatches.reduce((sum, batch) => sum + batch.effective_quantity, 0),
          batchCount: validBatches.filter(b => b.effective_quantity > 0).length
        };
      });
      
      return stockData;
    },
    staleTime: 30000, // Cache for 30 seconds
  });

  const handleDelete = async (item: InventoryItem) => {
    try {
      Logger.info('Checking item usage', { sku: item.sku });

      // Check if there are any active batches
      if (stockInfo[item.sku]?.batchCount > 0) {
        toast({
          variant: "destructive",
          title: "Cannot delete item",
          description: `This item has ${stockInfo[item.sku].batchCount} active batches. Please delete all batches first.`
        });
        return;
      }

      // Check if the item is used in any invoices
      const { data: invoiceItems, error: checkError } = await supabase
        .from('invoice_items')
        .select('id')
        .eq('sku', item.sku);

      if (checkError) {
        Logger.error('Error checking item usage', checkError);
        throw checkError;
      }

      if (invoiceItems && invoiceItems.length > 0) {
        toast({
          variant: "destructive",
          title: "Cannot delete item",
          description: "This item is used in one or more invoices and cannot be deleted."
        });
        return;
      }

      Logger.info('Deleting item', { sku: item.sku });

      const { error: deleteError } = await supabase
        .from('inventory')
        .delete()
        .eq('sku', item.sku);

      if (deleteError) {
        Logger.error('Error deleting item', deleteError);
        throw deleteError;
      }

      Logger.info('Item deleted successfully', { sku: item.sku });
      toast({
        title: "Success",
        description: "Item deleted successfully"
      });

      // Refresh the page to update the list
      window.location.reload();
    } catch (error: any) {
      Logger.error('Error in handleDelete', error);
      toast({
        variant: "destructive",
        title: "Error deleting item",
        description: error.message
      });
    }
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow className="bg-gray-50">
              <TableHead className="text-gray-900 w-[180px]">SKU</TableHead>
              <TableHead className="text-gray-900">Name</TableHead>
              <TableHead className="text-gray-900">Description</TableHead>
              <TableHead className="text-gray-900">Available</TableHead>
              <TableHead className="text-gray-900">Active Batches</TableHead>
              <TableHead className="text-gray-900">VAT Rate (%)</TableHead>
              <TableHead className="text-gray-900">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {inventory?.map((item) => (
              <TableRow key={item.sku} className="hover:bg-gray-50">
                <TableCell className="text-gray-900 whitespace-nowrap">{item.sku}</TableCell>
                <TableCell>
                  <TooltipProvider delayDuration={300}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="truncate max-w-[300px]">
                          {item.item_name}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent 
                        side="right"
                        className="bg-white p-3 rounded-lg shadow-lg border border-gray-200"
                      >
                        <div className="text-sm text-gray-900 whitespace-pre-line leading-relaxed">
                          {item.item_name}
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableCell>
                <TableCell className="text-gray-900">{item.description}</TableCell>
                <TableCell className="text-gray-900">
                  {item.is_weight_based ? (
                    <span>{stockInfo[item.sku]?.totalQuantity || 0} kg</span>
                  ) : (
                    <span>{stockInfo[item.sku]?.totalQuantity || 0}</span>
                  )}
                </TableCell>
                <TableCell className="text-gray-900">
                  {stockInfo[item.sku]?.batchCount || 0}
                </TableCell>
                <TableCell className="text-gray-900">{item.vat_rate}%</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {canManageInventory && (
                      <>
                        <EditInventoryDialog 
                          item={item} 
                          onItemUpdated={onItemUpdated}
                        />
                        <button
                          onClick={() => {
                            if (window.confirm('Are you sure you want to delete this item?')) {
                              handleDelete(item);
                            }
                          }}
                          className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
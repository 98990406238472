import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { Batch } from "@/types/batch";
import type { InventoryItem } from "@/types/inventory";
import { ViewBatchDialog } from "./ViewBatchDialog";
import { EditBatchDialog } from "./EditBatchDialog";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import Logger from "@/utils/logger";
import { Eye, Trash2 } from "lucide-react";
import { AlertCircle } from "lucide-react";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { BatchAuditButton } from "./BatchAuditButton";
import { ReconciliationModal } from "./ReconciliationModal";
import { useAuth } from '@/contexts/AuthContext';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface InvoiceItem {
  invoice: {
    invoice_number: string;
    company: string;
    date: string;
  };
}

interface BatchWithItem extends Batch {
  item_name?: string;
  balance?: {
    storedQuantity: number;
    calculatedQuantity: number;
    needsReconciliation: boolean;
  };
}

interface BatchesTableProps {
  batches: Batch[];
  onBatchUpdated: () => void;
}

export function BatchesTable({ batches, onBatchUpdated }: BatchesTableProps) {
  const { toast } = useToast();
  const { canEdit, canAddBatches } = useAuth();
  const [batchesWithItems, setBatchesWithItems] = useState<BatchWithItem[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<BatchWithItem | null>(null);
  const [reconciliationOpen, setReconciliationOpen] = useState(false);

  useEffect(() => {
    const fetchItemNames = async () => {
      try {
        // Get all unique SKUs from batches
        const skus = [...new Set(batches.map(batch => batch.sku))];
        
        // Fetch item names for these SKUs
        const { data: items, error } = await supabase
          .from('inventory')
          .select('sku, item_name')
          .in('sku', skus);

        if (error) throw error;

        // Create a map of SKU to item name
        const skuToName = new Map(items?.map(item => [item.sku, item.item_name]));

        // Add item names to batches
        const enrichedBatches = batches.map(batch => ({
          ...batch,
          item_name: skuToName.get(batch.sku) || 'Unknown Item'
        }));

        setBatchesWithItems(enrichedBatches);
      } catch (error) {
        Logger.error('Error fetching item names', error);
        setBatchesWithItems(batches.map(batch => ({ ...batch, item_name: 'Unknown Item' })));
      }
    };

    fetchItemNames();
  }, [batches]);

  const handleAuditComplete = (results: any[]) => {
    // Create a map of batch numbers to their balance results
    const balanceMap = new Map(results.map(result => [
      result.batchNumber,
      {
        storedQuantity: result.storedQuantity,
        calculatedQuantity: result.calculatedQuantity,
        needsReconciliation: true
      }
    ]));

    // Update batchesWithItems with balance information
    setBatchesWithItems(prev => prev.map(batch => ({
      ...batch,
      balance: balanceMap.get(batch.batch_number)
    })));

    if (results.length > 0) {
      toast({
        title: "Audit Complete",
        description: `Found ${results.length} batch${results.length === 1 ? '' : 'es'} with balance discrepancies.`
      });
    } else {
      toast({
        title: "Audit Complete",
        description: "All batch balances are correct."
      });
    }
  };

  const handleDelete = async (batch: Batch) => {
    try {
      Logger.info('Checking batch usage', { batch_number: batch.batch_number });

      // Get all invoices using this batch
      const { data: items, error: checkError } = await supabase
        .from('invoice_items')
        .select(`
          invoice:invoices!inner (
            invoice_number,
            company,
            date
          )
        `)
        .eq('batch_number', batch.batch_number);

      if (checkError) {
        Logger.error('Error checking batch usage', checkError);
        throw checkError;
      }

      if (items && items.length > 0) {
        const invoiceList = (items as unknown as InvoiceItem[])
          .map(item => {
            const date = new Date(item.invoice.date).toLocaleDateString();
            return `${item.invoice.invoice_number} (${item.invoice.company}, ${date})`;
          })
          .join('\n');

        toast({
          variant: "destructive",
          title: "Cannot delete batch",
          description: `This batch is used in the following invoices:\n${invoiceList}`
        });
        return;
      }

      Logger.info('Deleting batch', { batch_number: batch.batch_number });

      const { error: batchError } = await supabase
        .from('batches')
        .delete()
        .eq('batch_number', batch.batch_number);

      if (batchError) {
        Logger.error('Error deleting batch', batchError);
        throw batchError;
      }

      Logger.info('Batch deleted successfully', { batch_number: batch.batch_number });
      toast({
        title: "Success",
        description: "Batch deleted successfully"
      });

      // Refresh the page to update the list
      window.location.reload();
    } catch (error: any) {
      Logger.error('Error in handleDelete', error);
      toast({
        variant: "destructive",
        title: "Error deleting batch",
        description: error.message
      });
    }
  };

  const BalanceIndicator = ({ 
    storedQuantity, 
    calculatedQuantity, 
    isWeightBased,
    itemName,
    sku
  }: { 
    storedQuantity: number, 
    calculatedQuantity: number,
    isWeightBased: boolean,
    itemName: string,
    sku: string
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    if (storedQuantity === calculatedQuantity) return null;

    return (
      <>
        <AlertCircle 
          className="h-4 w-4 text-yellow-500 cursor-pointer" 
          onClick={() => setIsOpen(true)}
        />
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Balance Discrepancy Found</DialogTitle>
            </DialogHeader>
            <div>
              <p className="font-medium">{itemName}</p>
              <p className="text-sm text-gray-500">SKU: {sku}</p>
              
              <div className="mt-4 p-4 bg-orange-50 rounded-lg">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-600">Current System Balance</p>
                    <p className="font-medium">{storedQuantity} {isWeightBased ? 'kg' : ''}</p>
                    <p className="text-xs text-gray-500">Currently recorded in system</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">Expected Balance</p>
                    <p className="font-medium">{calculatedQuantity} {isWeightBased ? 'kg' : ''}</p>
                    <p className="text-xs text-gray-500">Based on transaction history</p>
                  </div>
                </div>
                
                <div className="mt-4">
                  <p className="text-sm font-medium text-red-600">Discrepancy</p>
                  <p className="text-sm">
                    {Math.abs(storedQuantity - calculatedQuantity)} {isWeightBased ? 'kg' : ''} 
                    {storedQuantity > calculatedQuantity ? ' extra' : ' missing'} in system
                  </p>
                </div>
              </div>

              <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                <p className="text-sm font-medium text-blue-700">About This Update</p>
                <p className="text-sm text-blue-600 mt-1">
                  The system balance will be updated to match the expected balance of {calculatedQuantity} {isWeightBased ? 'kg' : ''}, 
                  which has been calculated from the initial quantity minus all finalized invoice transactions. 
                  This ensures your inventory records match the actual stock movements.
                </p>
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button 
                onClick={async () => {
                  const { error } = await supabase
                    .from('batches')
                    .update({ available_quantity: calculatedQuantity })
                    .eq('sku', sku);

                  if (error) {
                    toast({
                      title: "Error",
                      description: "Failed to update balance",
                      variant: "destructive"
                    });
                    return;
                  }

                  toast({
                    title: "Success",
                    description: "Balance updated successfully"
                  });
                  setIsOpen(false);
                }}
              >
                Update to {calculatedQuantity} {isWeightBased ? 'kg' : ''}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <Table>
        <TableHeader>
          <TableRow className="bg-gray-50">
            <TableHead className="text-gray-900 w-[180px]">Item Name</TableHead>
            <TableHead className="text-gray-900">Batch #</TableHead>
            <TableHead className="text-gray-900">SKU</TableHead>
            <TableHead className="text-gray-900">Original Qty</TableHead>
            <TableHead className="text-gray-900">Available</TableHead>
            <TableHead className="text-gray-900">Expiry Date</TableHead>
            <TableHead className="text-gray-900">Date Received</TableHead>
            <TableHead className="text-gray-900">Cost</TableHead>
            <TableHead className="text-gray-900">Warehouse</TableHead>
            <TableHead className="text-gray-900 w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {batches.map((batch) => (
            <TableRow key={batch.batch_number}>
              <TableCell>
                <TooltipProvider delayDuration={300}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div 
                        className="truncate max-w-[300px] cursor-pointer hover:text-blue-600"
                        onClick={() => setSelectedBatch(batch)}
                      >
                        {batch.item_name}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent 
                      side="right"
                      className="bg-white p-3 rounded-lg shadow-lg border border-gray-200"
                    >
                      <div className="text-sm text-gray-900 whitespace-pre-line leading-relaxed">
                        {batch.item_name}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
              <TableCell className="text-gray-900 whitespace-nowrap">{batch.batch_number}</TableCell>
              <TableCell className="text-gray-900 whitespace-nowrap">{batch.sku}</TableCell>
              <TableCell className="text-gray-900 whitespace-nowrap">
                {batch.weight_kilos !== null 
                  ? `${batch.weight_kilos} kg` 
                  : batch.quantity}
              </TableCell>
              <TableCell className="text-gray-900">
                {batch.weight_kilos !== null
                  ? `${batch.available_quantity ?? batch.weight_kilos} kg`
                  : (batch.available_quantity ?? batch.quantity)}
              </TableCell>
              <TableCell className="text-gray-900 whitespace-nowrap">
                {new Date(batch.expiry_date).toLocaleDateString()}
              </TableCell>
              <TableCell className="text-gray-900 whitespace-nowrap">
                {new Date(batch.date_received).toLocaleDateString()}
              </TableCell>
              <TableCell className="text-gray-900 whitespace-nowrap">
                {batch.purchase_cost !== null && batch.purchase_cost !== undefined 
                  ? `CHF ${batch.purchase_cost.toFixed(2)}` 
                  : '-'}
              </TableCell>
              <TableCell className="text-gray-900 whitespace-nowrap">{batch.warehouse}</TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  {canEdit && (
                    <EditBatchDialog
                      batch={batch}
                      onBatchUpdated={onBatchUpdated}
                    />
                  )}
                  {(canEdit && canAddBatches) && (
                    <button
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this batch?')) {
                          handleDelete(batch);
                        }
                      }}
                      className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selectedBatch && selectedBatch.balance && (
        <ReconciliationModal
          open={reconciliationOpen}
          onClose={() => {
            setReconciliationOpen(false);
            setSelectedBatch(null);
          }}
          onReconcileComplete={() => {
            // Refresh the page only after successful reconciliation
            window.location.reload();
          }}
          batchNumber={selectedBatch.batch_number}
          storedQuantity={selectedBatch.balance.storedQuantity}
          calculatedQuantity={selectedBatch.balance.calculatedQuantity}
          isWeightBased={selectedBatch.weight_kilos !== null}
          itemName={selectedBatch.item_name}
          sku={selectedBatch.sku}
        />
      )}
      {selectedBatch && (
        <ViewBatchDialog 
          batch={selectedBatch}
          open={!!selectedBatch}
          onOpenChange={(open) => !open && setSelectedBatch(null)}
        />
      )}
    </div>
  );
}
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { InventoryTable } from "@/components/inventory/InventoryTable";
import { MobileHeader } from "@/components/layout/MobileHeader";
import type { InventoryItem } from "@/types/inventory";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { MobileInventoryView } from "@/components/inventory/MobileInventoryView";
import { EditInventoryDialog } from "@/components/inventory/EditInventoryDialog";
import { AddProductDialog } from "@/components/inventory/AddProductDialog";
import { ImportInventoryButton } from "@/components/inventory/ImportInventoryButton";
import { ExportInventoryButton } from "@/components/inventory/ExportInventoryButton";

export default function InventoryPage() {
  const [filterText, setFilterText] = useState("");
  const [lastRefreshTime, setLastRefreshTime] = useState(() => new Date());

  const { data: inventory, refetch: refetchInventory } = useQuery({
    queryKey: ['inventory'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('inventory')
        .select('*')
        .order('item_name');
      
      if (error) throw error;
      return data as InventoryItem[];
    },
    staleTime: Infinity
  });

  const { data: batches, refetch: refetchBatches } = useQuery({
    queryKey: ['batches'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('batches')
        .select('*');
      
      if (error) throw error;
      return data;
    },
    staleTime: Infinity
  });

  const availableQuantities = React.useMemo(() => {
    if (!batches) return {};
    return batches.reduce((acc, batch) => {
      if (!acc[batch.sku]) acc[batch.sku] = 0;
      acc[batch.sku] += (batch.available_quantity || 0);
      return acc;
    }, {} as Record<string, number>);
  }, [batches]);

  const handleRefresh = async () => {
    await Promise.all([refetchInventory(), refetchBatches()]);
    setLastRefreshTime(new Date());
  };

  const filteredInventory = inventory?.filter(item => 
    item.sku.toLowerCase().includes(filterText.toLowerCase()) ||
    item.item_name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className="min-h-screen">
      {/* Mobile View */}
      <div className="lg:hidden">
        <MobileInventoryView
          inventory={filteredInventory || []}
          filterText={filterText}
          onFilterChange={setFilterText}
          onInventoryChange={refetchInventory}
          stockInfo={availableQuantities}
        />
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block min-h-screen">
        <div className="flex items-center gap-3 mb-2">
          <h1 className="text-2xl font-bold">Master Inventory</h1>
          <Badge variant="secondary" className="h-7 px-3">
            {inventory?.length || 0} items
          </Badge>
          <div className="text-sm text-gray-500">
            Last updated: {lastRefreshTime.toLocaleString()}
          </div>
        </div>

        <div className="mb-8 flex items-center justify-between">
          <div className="flex-1 max-w-sm">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
              <Input
                placeholder="Search by SKU or name..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="pl-8"
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <ImportInventoryButton onImportComplete={refetchInventory} />
            <ExportInventoryButton />
            <AddProductDialog onProductAdded={refetchInventory} />
          </div>
        </div>

        <InventoryTable inventory={filteredInventory || []} onItemUpdated={refetchInventory} />
      </div>
    </div>
  );
}

import React from 'react';
import { InventoryItem } from "@/types/inventory";
import { Input } from "@/components/ui/input";
import { Search, Plus, Pencil, Trash2 } from "lucide-react";
import { AddProductDialog } from "./AddProductDialog";
import { EditInventoryDialog } from "./EditInventoryDialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { MobileHeader } from '../layout/MobileHeader';

interface MobileInventoryViewProps {
  inventory: InventoryItem[];
  filterText: string;
  onFilterChange: (value: string) => void;
  stockInfo: Record<string, { totalQuantity: number; batchCount: number; }>;
  onInventoryChange: () => void;
}

export function MobileInventoryView({
  inventory,
  filterText,
  onFilterChange,
  stockInfo,
  onInventoryChange
}: MobileInventoryViewProps) {
  const { canManageInventory } = useAuth();
  const { toast } = useToast();

  const handleDelete = async (item: InventoryItem) => {
    if (!window.confirm('Are you sure you want to delete this item?')) return;

    // Check if item has active batches
    if (stockInfo[item.sku]?.totalQuantity > 0) {
      toast({
        variant: "destructive",
        title: "Cannot delete item",
        description: "This item has active batches. Please delete all batches first."
      });
      return;
    }

    const { error } = await supabase
      .from('inventory')
      .delete()
      .eq('sku', item.sku);

    if (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Could not delete item. It may be referenced in invoices."
      });
      return;
    }

    toast({
      title: "Success",
      description: "Item deleted successfully"
    });
    onInventoryChange();
  };

  return (
    <div className="lg:hidden">
      <MobileHeader />
      <div className="fixed top-14 left-0 right-0 bottom-0 bg-white z-10 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
        {/* Header */}
        <div className="px-4 py-3 bg-white border-b">
          <h1 className="text-xl font-bold mb-2">Inventory</h1>
          <div className="flex items-center gap-2">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Search by SKU or name..."
                value={filterText}
                onChange={(e) => onFilterChange(e.target.value)}
                className="pl-9 pr-3 w-full"
              />
            </div>
            {canManageInventory && (
              <div className="flex-none">
                <AddProductDialog 
                  onProductAdded={onInventoryChange}
                  variant="icon"
                />
              </div>
            )}
          </div>
        </div>

        {/* Items List */}
        {inventory.map((item) => (
          <div key={item.sku} className="px-4 py-2 bg-white border-b">
            <div className="flex justify-between items-start gap-2">
              <h3 className="text-sm font-medium text-gray-900 leading-tight max-w-[75%]">{item.item_name}</h3>
              <div className="flex items-center gap-2">
                {canManageInventory && (
                  <>
                    <EditInventoryDialog
                      item={item}
                      onItemUpdated={onInventoryChange}
                      trigger={
                        <button className="text-blue-600 hover:text-blue-800">
                          <Pencil className="h-4 w-4" />
                        </button>
                      }
                    />
                    <button 
                      onClick={() => handleDelete(item)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              <div>SKU: {item.sku}</div>
              <div className="flex gap-4">
                <span>Available Qty: {item.is_weight_based
                  ? `${stockInfo[item.sku]?.totalQuantity || 0} kg`
                  : stockInfo[item.sku]?.totalQuantity || 0}
                </span>
                <span>Active Batches: {stockInfo[item.sku]?.batchCount || 0}</span>
              </div>
              <div>VAT: {item.vat_rate}%</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

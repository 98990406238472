import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import Index from '@/pages/Index';
import MasterInventory from "@/pages/MasterInventory";
import Batches from "@/pages/Batches";
import Invoices from "@/pages/Invoices";
import ExpiryCalendar from "@/pages/ExpiryCalendar";
import Login from "@/pages/Login";
import { Unauthorized } from "@/pages/Unauthorized";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";
import { Package, Calendar, Info, Menu, LogOut, Loader2, LayoutDashboard } from "lucide-react";
import Home from "@/pages/Home";
import Dashboard from "@/pages/Dashboard";
import Guide from "@/pages/Guide";

export default function Routes() {
  const { user, isInitialized } = useAuth();

  // Show loading state while initializing
  if (!isInitialized) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  // If not logged in, show home page and login page
  if (!user) {
    return (
      <RouterRoutes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </RouterRoutes>
    );
  }

  // Main app routes for authenticated users
  return (
    <RouterRoutes>
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/home" element={<Home />} />
      <Route element={<Index />}>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MasterInventory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inventory"
          element={
            <ProtectedRoute>
              <MasterInventory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/batches"
          element={
            <ProtectedRoute>
              <Batches />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoute>
              <Invoices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/expiry-calendar"
          element={
            <ProtectedRoute>
              <ExpiryCalendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          path="/guide"
          element={
            <ProtectedRoute>
              <Guide />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </RouterRoutes>
  );
}
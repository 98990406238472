import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { 
  Loader2, 
  Package, 
  ArrowDownLeft, 
  ArrowUpRight,
  XCircle,
  CircleX,
  AlertCircle,
  AlertTriangleIcon,
  CheckCircle2,
  Thermometer, 
  ThermometerSnowflake, 
  ThermometerSun, 
  Building2,
  Receipt
} from 'lucide-react';
import { format, parseISO, isValid } from 'date-fns';
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { useToast } from "@/components/ui/use-toast";
import { MobileHeader } from "@/components/layout/MobileHeader";
import { useIsMobile } from "@/hooks/use-mobile";
import { ExportTablesButton } from "@/components/dashboard/ExportTablesButton";

interface BatchData {
  batch_number: string;
  sku: string;
  quantity: number | null;
  weight_kilos: number | null;
  expiry_date: string;
  date_received: string;
  purchase_cost: number;
  warehouse: string;
  available_quantity: number | null;
  item_name: string;
}

interface InvoiceItemData {
  id: string;
  quantity: number | null;
  weight_kilos: number | null;
  measurement_type: string;
  batch_number: string;
}

interface InvoiceData {
  invoice_number: string;
  date: string;
  company: string;
  status: string;
  invoice_items: InvoiceItemData[];
}

interface DashboardMetrics {
  totalValue: number;
  locationValues: { location: string; value: number }[];
  expiryValues: { range: string; value: number; count: number }[];
  recentActivity: {
    id: string;
    type: 'inbound' | 'outbound';
    date: string;
    value: number;
    company: string;
    details: {
      item_name: string;
      quantity: number;
      measurement_type: 'units' | 'weight';
      weight_kilos?: number;
      location?: string;
    }[];
  }[];
  topProducts: {
    item_name: string;
    sku: string;
    total_quantity: number;
    total_value: number;
  }[];
  batches: BatchData[];
}

const formatDate = (dateString: string) => {
  return format(new Date(dateString), 'dd.MM.yyyy');
};

const formatExpiryTime = (expiryDate: string | null) => {
  if (!expiryDate) return 'No expiry date';
  
  try {
    const expiry = parseISO(expiryDate);
    if (!isValid(expiry)) return 'Invalid date';
    
    const now = new Date();
    const days = Math.ceil((expiry.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    
    if (days <= 0) return 'Expired';
    if (days < 7) return `${days} day${days === 1 ? '' : 's'}`;
    if (days < 30) {
      const weeks = Math.floor(days / 7);
      const remainingDays = days % 7;
      return `${weeks} week${weeks === 1 ? '' : 's'}${remainingDays > 0 ? `, ${remainingDays} day${remainingDays === 1 ? '' : 's'}` : ''}`;
    }
    return formatDate(expiryDate);
  } catch (error) {
    console.error('Error formatting expiry date:', error);
    return 'Invalid date';
  }
};

const getTemperatureIcon = (location: string) => {
  switch (location) {
    case '(+3°C)':
      return <Thermometer className="w-5 h-5 text-blue-500" />;
    case '(-25°C)':
      return <ThermometerSnowflake className="w-5 h-5 text-blue-700" />;
    case '(+25°C)':
      return <ThermometerSun className="w-5 h-5 text-orange-500" />;
    case 'Paradiso':
      return <Building2 className="w-5 h-5 text-gray-600" />;
    case 'Consignment':
      return <Package className="w-5 h-5 text-purple-500" />;
    default:
      return <Building2 className="w-5 h-5 text-gray-400" />;
  }
};

const calculateExpiryMetrics = (batches: BatchData[]) => {
  const now = new Date();
  const expiryRanges = {
    expired: { value: 0, count: 0 },
    '30 days': { value: 0, count: 0 },
    '60 days': { value: 0, count: 0 },
    '90 days': { value: 0, count: 0 },
  };

  batches.forEach((batch) => {
    if (!batch.expiry_date) return;
    
    try {
      const expiryDate = parseISO(batch.expiry_date);
      if (!isValid(expiryDate)) return;
      
      const daysUntilExpiry = Math.ceil((expiryDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      const quantity = batch.available_quantity ?? batch.weight_kilos ?? batch.quantity ?? 0;
      const cost = batch.purchase_cost ?? 0;
      const value = Number((cost * quantity).toFixed(2));
      
      if (value > 0) {
        if (daysUntilExpiry <= 0) {
          expiryRanges.expired.value += value;
          expiryRanges.expired.count++;
        } else if (daysUntilExpiry <= 30) {
          expiryRanges['30 days'].value += value;
          expiryRanges['30 days'].count++;
        } else if (daysUntilExpiry <= 60) {
          expiryRanges['60 days'].value += value;
          expiryRanges['60 days'].count++;
        } else if (daysUntilExpiry <= 90) {
          expiryRanges['90 days'].value += value;
          expiryRanges['90 days'].count++;
        }
      }
    } catch (error) {
      console.error('Error processing batch:', batch.batch_number, error);
    }
  });

  return Object.entries(expiryRanges).map(([range, { value, count }]) => ({
    range,
    value,
    count
  }));
};

export default function Dashboard() {
  const { toast } = useToast();
  const isMobile = useIsMobile();

  const { data: metrics, isLoading, error } = useQuery<DashboardMetrics>({
    queryKey: ['dashboard-metrics'],
    queryFn: async () => {
      try {
        // First get all batches to get their costs
        const { data: batchData, error: batchError } = await supabase
          .from('batches')
          .select(`
            batch_number,
            purchase_cost
          `);

        if (batchError) {
          console.error('Error fetching batches:', batchError);
          throw batchError;
        }

        // Create a map of batch numbers to costs
        const batchCosts = new Map(
          batchData?.map(batch => [batch.batch_number, batch.purchase_cost]) || []
        );

        console.log('Batch costs:', Object.fromEntries(batchCosts));

        // First get all batches with their SKUs
        const { data: batchDataFull, error: batchErrorFull } = await supabase
          .from('batches')
          .select(`
            batch_number,
            sku,
            quantity,
            weight_kilos,
            expiry_date,
            date_received,
            purchase_cost,
            warehouse,
            available_quantity,
            inventory:inventory!inner (
              item_name
            )
          `)
          .gt('available_quantity', 0) as { 
            data: (Omit<BatchData, 'item_name'> & { 
              inventory: { item_name: string } 
            })[] | null;
            error: any;
          };

        if (batchErrorFull) {
          console.error('Error fetching batches:', batchErrorFull);
          toast({
            variant: "destructive",
            title: "Error fetching batches",
            description: batchErrorFull.message
          });
          throw batchErrorFull;
        }

        // Get recent invoices
        const { data: invoiceData, error: invoiceError } = await supabase
          .from('invoices')
          .select(`
            invoice_number,
            date,
            company,
            status,
            invoice_items (
              id,
              quantity,
              weight_kilos,
              measurement_type,
              batch_number
            )
          `)
          .eq('status', 'final')
          .order('date', { ascending: false })
          .limit(10);

        if (invoiceError) {
          console.error('Error fetching invoices:', invoiceError);
          throw invoiceError;
        }

        const invoices = invoiceData || [];
        console.log('Invoices:', invoices);

        const batches = (batchDataFull || []).map(batch => ({
          ...batch,
          item_name: batch.inventory?.item_name || 'Unknown Item'
        })) as BatchData[];

        // Process location values
        const locationMap = new Map<string, number>();
        batches.forEach(batch => {
          const value = batch.purchase_cost * (batch.available_quantity || batch.weight_kilos || 0);
          const current = locationMap.get(batch.warehouse) || 0;
          locationMap.set(batch.warehouse, current + value);
        });

        const locationValues = Array.from(locationMap.entries())
          .map(([location, value]) => ({ location, value }))
          .sort((a, b) => b.value - a.value);

        // Calculate expiry ranges
        const expiryValues = calculateExpiryMetrics(batches);

        // Process top products
        const productTotals = invoices.reduce((acc, invoice) => {
          invoice.invoice_items?.forEach(item => {
            const batch = batches.find(b => b.batch_number === item.batch_number);
            if (!batch) return;
            
            const key = batch.item_name;
            if (!acc.has(key)) {
              acc.set(key, {
                item_name: batch.item_name,
                sku: batch.sku || '',
                total_quantity: 0,
                total_value: 0
              });
            }
            
            const current = acc.get(key)!;
            current.total_quantity += item.quantity || 0;
            current.total_value += (item.quantity || 0) * (batchCosts.get(item.batch_number) || 0);
          });
          return acc;
        }, new Map());

        const topProducts = Array.from(productTotals.values())
          .sort((a, b) => 
            // First sort by quantity
            b.total_quantity - a.total_quantity || 
            // If quantities are equal, sort by value
            b.total_value - a.total_value
          )
          .slice(0, 10);

        // Process recent activity
        console.log('Invoices:', invoices);
        const recentActivity = [
          // Outbound transactions from invoices
          ...invoices.map(invoice => {
            const total = invoice.invoice_items?.reduce((sum, item) => {
              console.log('Processing item:', item);
              const cost = batchCosts.get(item.batch_number) || 0;
              console.log('Processing item:', item.batch_number, 'Cost:', cost);
              
              const amount = item.measurement_type === 'weight' 
                ? (item.weight_kilos || 0) * cost
                : (item.quantity || 0) * cost;
              console.log('Item amount:', amount);
              return sum + amount;
            }, 0) || 0;
            console.log('Invoice total:', total);

            const details = invoice.invoice_items?.map(item => {
              const batch = batches.find(b => b.batch_number === item.batch_number);
              return {
                item_name: batch?.item_name || 'Unknown Item',
                quantity: item.quantity || 0,
                measurement_type: item.measurement_type as 'units' | 'weight',
                weight_kilos: item.weight_kilos
              };
            }) || [];

            return {
              id: invoice.invoice_number,
              type: 'outbound' as const,
              date: new Date(invoice.date).toISOString(),
              value: total,
              company: invoice.company,
              details
            };
          }),
          // Inbound transactions from batches
          ...batches.map(batch => ({
            id: batch.batch_number,
            type: 'inbound' as const,
            date: new Date(batch.date_received).toISOString(),
            value: batch.purchase_cost * (batch.available_quantity || batch.weight_kilos || 0),
            company: `Batch ${batch.batch_number}`,
            details: [{
              item_name: batch.item_name,
              quantity: batch.available_quantity || 0,
              measurement_type: batch.weight_kilos ? 'weight' as const : 'units' as const,
              weight_kilos: batch.weight_kilos,
              location: batch.warehouse
            }]
          }))
        ].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
         .slice(0, 10);

        const totalValue = locationValues.reduce((sum, loc) => sum + loc.value, 0);

        return {
          totalValue,
          locationValues,
          expiryValues,
          recentActivity,
          topProducts,
          batches
        };
      } catch (error) {
        console.error('Error in dashboard metrics:', error);
        toast({
          variant: "destructive",
          title: "Error loading dashboard",
          description: error instanceof Error ? error.message : "An unexpected error occurred"
        });
        throw error;
      }
    }
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    toast({
      variant: "destructive",
      title: "Error loading dashboard",
      description: "There was a problem loading the dashboard data."
    });
    return null;
  }

  return (
    <>
      {isMobile && <MobileHeader title="Dashboard" />}
      {isMobile ? (
        // Mobile Layout
        <div className="p-4 space-y-4 pb-20 mt-14">
          {/* Mobile Header */}
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-bold">Inventory Dashboard</h1>
            <div className="flex flex-col gap-1">
              <span className="text-base font-semibold text-muted-foreground">
                Total Value: CHF {Math.round(metrics?.totalValue).toLocaleString(undefined, { minimumFractionDigits: 0 })}
              </span>
              <Badge variant="outline" className="text-sm self-start">
                Last updated: {format(new Date(), 'dd.MM.yyyy HH:mm')}
              </Badge>
            </div>
          </div>

          <div className="flex justify-end mb-4">
            <ExportTablesButton />
          </div>

          {/* Mobile Key Metrics */}
          <div className="space-y-3">
            <div className="rounded-lg border p-3 bg-red-50/50 dark:bg-red-950/20">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-sm text-muted-foreground">Expired</span>
                  <p className="text-lg font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === 'expired')?.value || 0).toLocaleString()}</p>
                </div>
                <CircleX className="h-6 w-6 text-black fill-current" />
              </div>
            </div>

            <div className="rounded-lg border p-3 bg-orange-50/50 dark:bg-orange-950/20">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-sm text-muted-foreground">Expiring in 30 days</span>
                  <p className="text-lg font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === '30 days')?.value || 0).toLocaleString()}</p>
                </div>
                <AlertTriangleIcon className="h-6 w-6 text-red-600 fill-current" />
              </div>
            </div>

            <div className="rounded-lg border p-3 bg-yellow-50/50 dark:bg-yellow-950/20">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-sm text-muted-foreground">Expiring in 60 days</span>
                  <p className="text-lg font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === '60 days')?.value || 0).toLocaleString()}</p>
                </div>
                <AlertTriangleIcon className="h-6 w-6 text-yellow-500 fill-current" />
              </div>
            </div>

            <div className="rounded-lg border p-3 bg-green-50/50 dark:bg-green-950/20">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-sm text-muted-foreground">Expiring in 90 days</span>
                  <p className="text-lg font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === '90 days')?.value || 0).toLocaleString()}</p>
                </div>
                <CheckCircle2 className="h-6 w-6 text-green-600 fill-current" />
              </div>
            </div>
          </div>

          {/* Mobile Location Distribution */}
          <Card className="p-4">
            <h2 className="text-lg font-bold mb-2">Inventory by Location</h2>
            <div className="space-y-4">
              {metrics?.locationValues.map(({ location, value }) => {
                const percentage = (value / metrics?.totalValue) * 100;
                return (
                  <div key={location} className="space-y-2">
                    <div className="flex items-center gap-2">
                      {getTemperatureIcon(location)}
                      <span className="font-medium">{location}</span>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="w-full bg-muted/10 rounded-full h-2">
                        <div 
                          className="bg-primary h-2 rounded-full transition-all" 
                          style={{ width: `${percentage}%` }} 
                        />
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="font-mono">CHF {Math.round(value).toLocaleString()}</span>
                        <span className="text-muted-foreground">{percentage.toFixed(1)}%</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>

          {/* Mobile Next Expiring Batches */}
          <Card className="p-4">
            <h2 className="text-lg font-bold mb-3">Next Expiring Batches</h2>
            <div className="space-y-3">
              {metrics?.batches
                ?.sort((a, b) => new Date(a.expiry_date).getTime() - new Date(b.expiry_date).getTime())
                .slice(0, 5)
                .map((batch) => (
                  <div key={batch.batch_number} className="border rounded-lg p-3 space-y-2">
                    <div>
                      <div className="font-medium">{batch.item_name}</div>
                      <div className="text-sm text-muted-foreground">{batch.batch_number}</div>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      <Badge variant="secondary" className="text-xs">
                        {formatExpiryTime(batch.expiry_date)}
                      </Badge>
                      <Badge variant="outline" className="text-xs">
                        {batch.warehouse}
                      </Badge>
                    </div>
                    <div className="text-sm text-muted-foreground">
                      <div className="flex items-center gap-1">
                        <span>CHF {Math.round(batch.purchase_cost * (batch.available_quantity || batch.weight_kilos || 0)).toLocaleString()}</span>
                        <span>•</span>
                        <span>{batch.available_quantity ? 
                          `${Math.round(batch.available_quantity)} units` : 
                          batch.weight_kilos ? 
                          `${Math.round(batch.weight_kilos)} kg` : 
                          '0'}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Card>

          {/* Mobile Most Sold Products */}
          <Card className="p-4">
            <h2 className="text-lg font-bold mb-3 flex items-center gap-2">
              <Receipt className="h-5 w-5" />
              Most Sold Products
            </h2>
            <div className="space-y-3">
              {metrics?.topProducts?.map((product) => (
                <div key={product.item_name} className="border rounded-lg p-3 space-y-2">
                  <div>
                    <div className="font-medium">{product.item_name}</div>
                    <div className="text-sm text-muted-foreground">{product.sku}</div>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    <Badge className="text-xs">
                      {Math.round(product.total_quantity).toLocaleString()} units
                    </Badge>
                    <Badge variant="outline" className="text-xs">
                      CHF {Math.round(product.total_value).toLocaleString()}
                    </Badge>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </div>
      ) : (
        // Desktop Layout
        <div className="p-4 space-y-4 pb-20 mt-14">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0">
            <div className="flex items-center gap-4">
              <h1 className="text-2xl sm:text-3xl font-bold">Inventory Dashboard</h1>
              <span className="text-lg sm:text-xl font-semibold text-muted-foreground">
                Total Value: CHF {Math.round(metrics?.totalValue).toLocaleString(undefined, { minimumFractionDigits: 0 })}
              </span>
            </div>
            <div className="flex items-center gap-3">
              <Badge variant="outline" className="text-sm whitespace-nowrap">
                Last updated: {format(new Date(), 'dd.MM.yyyy HH:mm')}
              </Badge>
              <ExportTablesButton />
            </div>
          </div>

          {/* Key Metrics */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="rounded-lg border p-4 h-[85px] bg-red-50/50 dark:bg-red-950/20 relative">
              <CircleX className="absolute top-3 right-3 h-6 w-6 text-black fill-current" />
              <span className="text-sm text-muted-foreground">Expired</span>
              <p className="text-2xl font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === 'expired')?.value || 0).toLocaleString()}</p>
            </div>

            <div className="rounded-lg border p-4 h-[85px] bg-orange-50/50 dark:bg-orange-950/20 relative">
              <AlertTriangleIcon className="absolute top-3 right-3 h-6 w-6 text-red-600 fill-current" />
              <span className="text-sm text-muted-foreground">Expiring in 30 days</span>
              <p className="text-2xl font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === '30 days')?.value || 0).toLocaleString()}</p>
            </div>

            <div className="rounded-lg border p-4 h-[85px] bg-yellow-50/50 dark:bg-yellow-950/20 relative">
              <AlertTriangleIcon className="absolute top-3 right-3 h-6 w-6 text-yellow-500 fill-current" />
              <span className="text-sm text-muted-foreground">Expiring in 60 days</span>
              <p className="text-2xl font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === '60 days')?.value || 0).toLocaleString()}</p>
            </div>

            <div className="rounded-lg border p-4 h-[85px] bg-green-50/50 dark:bg-green-950/20 relative">
              <CheckCircle2 className="absolute top-3 right-3 h-6 w-6 text-green-600 fill-current" />
              <span className="text-sm text-muted-foreground">Expiring in 90 days</span>
              <p className="text-2xl font-bold mt-1">CHF {Math.round(metrics?.expiryValues.find(e => e.range === '90 days')?.value || 0).toLocaleString()}</p>
            </div>
          </div>

          {/* Location Distribution and Next Expiring Batches */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Location Distribution */}
            <Card className="p-4 sm:p-6">
              <div className="space-y-4">
                <div>
                  <h2 className="text-xl sm:text-2xl font-bold">Inventory by Location</h2>
                  <p className="text-sm text-muted-foreground">Distribution across warehouses</p>
                </div>

                <div>
                  {metrics?.locationValues.map(({ location, value }) => {
                    const percentage = (value / metrics?.totalValue) * 100;
                    return (
                      <div key={location} className="flex items-center justify-between py-1 relative">
                        <div className="absolute inset-0 bg-muted/5" style={{ width: `${percentage}%` }} />
                        <div className="flex items-center gap-2 sm:gap-3 relative">
                          <div className="w-6 h-6 sm:w-7 sm:h-7 flex items-center justify-center">
                            {getTemperatureIcon(location)}
                          </div>
                          <span className="text-base sm:text-lg font-medium truncate">{location}</span>
                        </div>
                        <div className="flex items-center gap-2 sm:gap-6 relative">
                          <span className="font-mono text-base sm:text-lg">
                            CHF {Math.round(value).toLocaleString('de-CH', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                          </span>
                          <span className="text-base sm:text-lg text-muted-foreground w-16 sm:w-20 text-right">
                            {percentage.toFixed(1)}%
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>

            {/* Next Expiring Batches */}
            <Card>
              <CardHeader>
                <CardTitle>Next Expiring Batches</CardTitle>
              </CardHeader>
              <CardContent>
                <ScrollArea className="h-[300px]">
                  <div className="space-y-2">
                    {metrics?.batches
                      ?.sort((a, b) => new Date(a.expiry_date).getTime() - new Date(b.expiry_date).getTime())
                      .slice(0, 5)
                      .map((batch) => (
                        <div key={batch.batch_number} className="flex items-center justify-between rounded-lg border p-2">
                          <div className="flex-1 min-w-0">
                            <div className="flex items-center space-x-2">
                              <span className="font-medium truncate">{batch.item_name}</span>
                              <span className="text-muted-foreground truncate">({batch.batch_number})</span>
                            </div>
                            <div className="flex flex-wrap gap-2 text-sm text-muted-foreground">
                              <span className="truncate">{batch.warehouse}</span>
                              <span>•</span>
                              <span>CHF {Math.round(batch.purchase_cost * (batch.available_quantity || batch.weight_kilos || 0)).toLocaleString(undefined, { minimumFractionDigits: 0 })}</span>
                              <span>•</span>
                              <span>{formatDate(batch.expiry_date)}</span>
                              <span>•</span>
                              <span className="font-bold text-blue-600 dark:text-blue-400">{batch.available_quantity ? 
                                `${Math.round(batch.available_quantity)} units` : 
                                batch.weight_kilos ? 
                                `${Math.round(batch.weight_kilos)} kg` : 
                                '0'}</span>
                              <Badge variant="secondary" className="ml-1 text-xs bg-blue-100 text-blue-700 dark:bg-blue-800 dark:text-blue-200">
                                {formatExpiryTime(batch.expiry_date)}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          </div>

          {/* Most Sold Products */}
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Receipt className="h-5 w-5" />
                Most Sold Products
              </CardTitle>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[300px]">
                <div className="space-y-2">
                  {metrics?.topProducts?.map((product) => (
                    <div
                      key={product.item_name}
                      className="flex items-center justify-between rounded-lg border p-3 hover:bg-accent/50 transition-colors"
                    >
                      <div className="flex-1">
                        <div className="flex items-center justify-between gap-4">
                          <div className="flex items-center gap-2 truncate flex-1">
                            <span className="text-sm text-muted-foreground">
                              {product.sku}
                            </span>
                            <span className="font-medium truncate">
                              {product.item_name}
                            </span>
                          </div>
                          <div className="flex items-center gap-4">
                            <span className="font-medium text-blue-600 dark:text-blue-400 whitespace-nowrap">
                              {Math.round(product.total_quantity).toLocaleString()} units
                            </span>
                            <span className="font-bold text-blue-600 dark:text-blue-400 whitespace-nowrap">
                              CHF {Math.round(product.total_value).toLocaleString(undefined, { minimumFractionDigits: 0 })}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            </CardContent>
          </Card>

          {/* Recent Activity */}
          <Card>
            <CardHeader>
              <CardTitle>Recent Activity</CardTitle>
              <CardDescription>Latest inventory transactions</CardDescription>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[300px]">
                <div className="space-y-2">
                  {metrics?.recentActivity.map((activity) => (
                    <div
                      key={activity.id}
                      className="flex items-center justify-between rounded-lg border p-2"
                    >
                      <div className="flex items-center space-x-3 min-w-0">
                        <div className={cn(
                          "p-2 rounded-full flex-shrink-0",
                          activity.type === 'outbound' 
                            ? "bg-red-100 text-red-700 dark:bg-red-800 dark:text-red-200"
                            : "bg-green-100 text-green-700 dark:bg-green-800 dark:text-green-200"
                        )}>
                          {activity.type === 'outbound' ? <ArrowUpRight className="h-4 w-4" /> : <ArrowDownLeft className="h-4 w-4" />}
                        </div>
                        <div className="space-y-1 min-w-0">
                          <p className="text-sm font-medium leading-none truncate">
                            {activity.company}
                          </p>
                          <div className="text-xs text-muted-foreground space-y-0.5">
                            {activity.details.map((detail, idx) => (
                              <p key={idx} className="truncate">
                                {detail.item_name} • {
                                  detail.measurement_type === 'weight'
                                    ? `${Math.round(detail.weight_kilos)} kg`
                                    : `${Math.round(detail.quantity)} units`
                                }
                                {detail.location && ` • ${detail.location}`}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="text-right flex-shrink-0">
                        <p className="text-sm font-medium whitespace-nowrap">
                          CHF {Math.round(activity.value).toLocaleString(undefined, { minimumFractionDigits: 0 })}
                        </p>
                        <p className="text-xs text-muted-foreground">
                          {formatDate(activity.date)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
}

import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";

export function Unauthorized() {
  const { signOut } = useAuth();

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-[#304661] p-4">
      <div className="w-full max-w-md rounded-lg bg-white p-8 text-center shadow-lg">
        <h1 className="mb-4 text-2xl font-bold text-red-600">Access Denied</h1>
        <p className="mb-6 text-gray-600">
          You don't have permission to access this system. Please contact your administrator for access.
        </p>
        <Button
          onClick={signOut}
          className="w-full bg-gray-500 hover:bg-gray-600"
        >
          Sign Out
        </Button>
      </div>
    </div>
  );
}
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { BatchesTable } from "@/components/batches/BatchesTable";
import { MobileBatchesView } from "@/components/batches/MobileBatchesView";
import type { Batch } from "@/types/batch";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { AddBatchDialog } from "@/components/batches/AddBatchDialog";
import { BatchAuditButton } from "@/components/batches/BatchAuditButton";

const Batches = () => {
  const [filterText, setFilterText] = useState("");
  
  const { data: batches, refetch } = useQuery({
    queryKey: ['batches'],
    queryFn: async () => {
      // First get all batches with their SKUs
      const { data: batchData, error: batchError } = await supabase
        .from('batches')
        .select('*')
        .order('date_received', { ascending: false });
      
      if (batchError) throw batchError;

      // Then get all inventory items
      const { data: inventoryData, error: invError } = await supabase
        .from('inventory')
        .select('sku, item_name');

      if (invError) throw invError;

      // Create a map of SKU to item name
      const itemNameMap = new Map(inventoryData.map(item => [item.sku, item.item_name]));

      // Combine the data
      const enrichedBatches = batchData.map(batch => ({
        ...batch,
        item_name: itemNameMap.get(batch.sku) || 'Unknown Item'
      }));

      return enrichedBatches as Batch[];
    },
  });

  const filteredBatches = batches?.filter(batch =>
    batch.batch_number.toLowerCase().includes(filterText.toLowerCase()) ||
    batch.item_name.toLowerCase().includes(filterText.toLowerCase()) ||
    batch.sku.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div>
      {/* Mobile View */}
      <MobileBatchesView 
        batches={filteredBatches || []}
        filterText={filterText}
        onFilterChange={setFilterText}
        onBatchUpdated={refetch}
      />

      {/* Desktop View */}
      <div className="hidden lg:block min-h-screen">
        <div className="flex items-center gap-2 mb-4">
          <h1 className="text-2xl font-bold">Batch Management</h1>
          <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
            {batches?.length || 0} batches
          </div>
        </div>

        <div className="mb-8 flex items-center justify-between">
          <div className="relative w-96">
            <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Search by SKU, batch number or name..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="pl-9 pr-3"
            />
          </div>
          <div className="flex gap-2">
            <BatchAuditButton batches={batches || []} onAuditComplete={refetch} />
            <AddBatchDialog onBatchAdded={refetch} />
          </div>
        </div>

        <BatchesTable 
          batches={filteredBatches || []} 
          onBatchUpdated={refetch}
        />
      </div>
    </div>
  );
};

export default Batches;

import React, { useState } from 'react';
import { Menu, LogOut, Home, PackageSearch, ArrowDownToLine, ArrowUpToLine, Boxes, FileText, CalendarClock, LayoutDashboard } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';

interface MobileHeaderProps {
  title?: string;
}

export function MobileHeader({ title }: MobileHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const displayName = user?.email?.split('@')[0] || 'User';

  const navigation = [
    { name: 'Home', href: '/home', icon: Home },
    { name: 'Inventory', href: '/inventory', icon: PackageSearch },
    { name: 'Inbound', href: '/batches', icon: ArrowDownToLine, className: 'text-blue-500' },
    { name: 'Outbound', href: '/invoices', icon: ArrowUpToLine, className: 'text-red-500' },
    { name: 'Calendar', href: '/expiry-calendar', icon: CalendarClock },
    { name: 'Dashboard', href: '/dashboard', icon: LayoutDashboard },
  ];

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  // Get current page title
  const getPageTitle = () => {
    if (title) return title;
    const currentPage = navigation.find(item => item.href === location.pathname);
    return currentPage?.name || 'IMS';
  };

  return (
    <div className="lg:hidden">
      <header className="fixed top-0 left-0 right-0 z-50 bg-[#2C3E50] border-b border-[#34495E]">
        <div className="flex items-center justify-between h-14 px-4">
          <div className="flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 -ml-2 text-white"
            >
              <Menu className="h-6 w-6" />
            </button>
            <span className="ml-4 text-lg text-white font-semibold">{getPageTitle()}</span>
          </div>
          <div className="flex items-center">
            <span className="text-white text-sm mr-4">{displayName}</span>
            <button
              onClick={handleSignOut}
              className="p-2 -mr-2 text-white"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>

        {isOpen && (
          <>
            {/* Dark overlay */}
            <div 
              className="fixed inset-0 bg-black/50 z-40"
              onClick={() => setIsOpen(false)}
            />
            <nav className="absolute top-14 left-0 w-64 bg-[#2C3E50] border-r border-[#34495E] shadow-lg
                z-40">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`flex items-center gap-2 px-4 py-3 text-white hover:bg-[#99AE4C] transition-colors ${
                    location.pathname === item.href ? 'bg-[#99AE4C]' : ''
                  }`}
                  onClick={() => setIsOpen(false)}
                >
                  <item.icon className={`h-5 w-5 ${item.className || ''}`} />
                  {item.name}
                </Link>
              ))}
            </nav>
          </>
        )}
      </header>
      <div className="h-14" /> {/* Spacer for fixed header */}
    </div>
  );
}
// User role types and mappings
export type UserRole = 'admin' | 'editor' | 'guest' | 'inbound';

// Single source of truth for user role mappings
export const USER_ROLES: Record<string, UserRole> = {
  'swissibex@gmail.com': 'admin',
  'maria.matteoni@gmail.com': 'editor',
  'mremorgida@gmail.com': 'admin',
  'moisemateus@gmail.com': 'inbound',
  'damianogior@gmail.com': 'guest'
  // Add new inbound users here when needed
};
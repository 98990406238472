import React, { useState } from 'react';
import { Eye, Info, TrendingUp, TrendingDown, Trash2 } from 'lucide-react';
import { Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { AddBatchDialog } from './AddBatchDialog';
import { EditBatchDialog } from './EditBatchDialog';
import { MobileHeader } from '../layout/MobileHeader';
import { Batch } from '@/types/batch';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/components/ui/use-toast';
import Logger from '@/utils/logger';

interface MobileBatchesViewProps {
  batches: Batch[];
  filterText: string;
  onFilterChange: (text: string) => void;
  onBatchUpdated: () => void;
}

function PriceHistory({ sku, currentBatchNumber }: { sku: string; currentBatchNumber: string }) {
  const { data: recentBatches } = useQuery({
    queryKey: ['batch-history', sku],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('batches')
        .select('batch_number, date_received, purchase_cost')
        .eq('sku', sku)
        .order('date_received', { ascending: false })
        .limit(10);

      if (error) throw error;
      return data || [];
    },
  });

  if (!recentBatches?.length) return null;

  // Calculate percentage changes
  const batchesWithChanges = recentBatches.map((batch, index) => {
    let percentChange = null;
    if (index < recentBatches.length - 1) {
      const prevCost = recentBatches[index + 1].purchase_cost;
      const currentCost = batch.purchase_cost;
      percentChange = ((currentCost - prevCost) / prevCost) * 100;
    }
    return { ...batch, percentChange };
  });

  return (
    <div className="divide-y divide-gray-100">
      {batchesWithChanges.map((batch) => (
        <div 
          key={batch.batch_number}
          className={cn(
            "flex items-center justify-between px-3 py-2",
            batch.batch_number === currentBatchNumber ? "bg-blue-50" : "hover:bg-gray-50"
          )}
        >
          <div className="flex-1">
            <div className="font-medium">
              {new Date(batch.date_received).toLocaleDateString()}
            </div>
            <div className="text-sm text-gray-500 flex items-center gap-1">
              {batch.batch_number}
              {batch.batch_number === currentBatchNumber && (
                <span className="text-xs bg-blue-100 text-blue-800 px-1.5 py-0.5 rounded-sm">Current</span>
              )}
            </div>
          </div>
          <div className="text-right flex items-center gap-2">
            <div>
              <div className="font-medium">CHF {(batch.purchase_cost ?? 0).toFixed(2)}</div>
              {batch.percentChange !== null && (
                <div className={cn(
                  "text-sm flex items-center justify-end gap-0.5",
                  batch.percentChange > 0 ? "text-red-600" : batch.percentChange < 0 ? "text-green-600" : "text-gray-500"
                )}>
                  {batch.percentChange > 0 ? (
                    <TrendingUp className="h-3 w-3" />
                  ) : batch.percentChange < 0 ? (
                    <TrendingDown className="h-3 w-3" />
                  ) : null}
                  {batch.percentChange > 0 ? "+" : ""}
                  {batch.percentChange.toFixed(1)}%
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function MobileBatchesView({ batches, filterText, onFilterChange, onBatchUpdated }: MobileBatchesViewProps) {
  const [selectedBatch, setSelectedBatch] = useState<Batch | null>(null);
  const { canEdit } = useAuth();
  const { toast } = useToast();

  const handleDelete = async (batch: Batch) => {
    try {
      Logger.info('Checking batch usage', { batch_number: batch.batch_number });

      // Get all invoices using this batch
      const { data: items, error: checkError } = await supabase
        .from('invoice_items')
        .select(`
          invoice:invoices!inner (
            invoice_number,
            company,
            date
          )
        `)
        .eq('batch_number', batch.batch_number);

      if (checkError) {
        Logger.error('Error checking batch usage', checkError);
        throw checkError;
      }

      if (items && items.length > 0) {
        const invoiceList = (items as any[])
          .map(item => {
            const date = new Date(item.invoice.date).toLocaleDateString();
            return `${item.invoice.invoice_number} (${item.invoice.company}, ${date})`;
          })
          .join('\n');

        toast({
          variant: "destructive",
          title: "Cannot delete batch",
          description: `This batch is used in the following invoices:\n${invoiceList}`
        });
        return;
      }

      Logger.info('Deleting batch', { batch_number: batch.batch_number });

      const { error: batchError } = await supabase
        .from('batches')
        .delete()
        .eq('batch_number', batch.batch_number);

      if (batchError) {
        Logger.error('Error deleting batch', batchError);
        throw batchError;
      }

      Logger.info('Batch deleted successfully', { batch_number: batch.batch_number });
      toast({
        title: "Success",
        description: "Batch deleted successfully"
      });

      onBatchUpdated();
    } catch (error: any) {
      Logger.error('Error in handleDelete', error);
      toast({
        variant: "destructive",
        title: "Error deleting batch",
        description: error.message
      });
    }
  };

  // Fetch SKU details when dialog is opened
  const { data: skuDetails } = useQuery({
    queryKey: ['sku', selectedBatch?.sku, !!selectedBatch],
    queryFn: async () => {
      if (!selectedBatch) return null;
      const { data, error } = await supabase
        .from('inventory')
        .select('item_name, is_weight_based')
        .eq('sku', selectedBatch.sku)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: !!selectedBatch,
  });

  return (
    <div className="lg:hidden">
      <MobileHeader />
      <div className="fixed top-14 left-0 right-0 bottom-0 bg-white z-10 overflow-y-auto scrollbar-none">
        <div className="px-4 py-3 bg-white border-b">
          <h1 className="text-xl font-bold mb-2">Inbound</h1>
          <div className="flex items-center gap-2">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Search by SKU, batch number or name..."
                value={filterText}
                onChange={(e) => onFilterChange(e.target.value)}
                className="pl-9 pr-3 w-full"
              />
            </div>
            <div className="flex-none">
              <AddBatchDialog 
                onBatchAdded={onBatchUpdated}
                variant="icon"
              />
            </div>
          </div>
        </div>

        {batches.map((batch) => (
          <div key={batch.batch_number} className="px-4 py-2 border-b bg-white">
            <div className="flex justify-between items-start gap-2">
              <h3 
                className="text-sm font-medium text-gray-900 leading-tight max-w-[75%] cursor-pointer hover:text-blue-600"
                onClick={() => setSelectedBatch(batch)}
              >
                {batch.item_name}
              </h3>
              <div className="flex items-center gap-2">
                {canEdit && (
                  <EditBatchDialog
                    batch={batch}
                    onBatchUpdated={onBatchUpdated}
                  />
                )}
                {canEdit && (
                  <button
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this batch?')) {
                        handleDelete(batch);
                      }
                    }}
                    className="text-red-600 hover:text-red-800"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              <div>Batch: {batch.batch_number}</div>
              <div>SKU: {batch.sku}</div>
              <div className="flex gap-4">
                <span>Original Qty: {batch.weight_kilos !== null ? `${batch.weight_kilos} kg` : batch.quantity}</span>
                <span>Available Qty: {batch.weight_kilos !== null 
                  ? `${batch.available_quantity ?? batch.weight_kilos} kg` 
                  : (batch.available_quantity ?? batch.quantity)}
                </span>
              </div>
              <div>Expiry: {batch.expiry_date}</div>
            </div>
          </div>
        ))}
      </div>

      <Dialog open={!!selectedBatch} onOpenChange={() => setSelectedBatch(null)}>
        <DialogContent className="p-4">
          <DialogHeader>
            <DialogTitle className="text-lg font-bold mb-4">Batch {selectedBatch?.batch_number}</DialogTitle>
          </DialogHeader>
          {selectedBatch && skuDetails && (
            <div className="space-y-4">
              <div className="border-b pb-3">
                <div className="text-xs text-gray-500">Product Name</div>
                <div className="text-base font-medium text-gray-900">{skuDetails.item_name}</div>
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-3">
                <div>
                  <div className="text-xs text-gray-500">SKU</div>
                  <div className="text-sm font-medium">{selectedBatch.sku}</div>
                </div>
                <div>
                  <div className="text-xs text-gray-500">Warehouse</div>
                  <div className="text-sm font-medium">{selectedBatch.warehouse}</div>
                </div>
                <div>
                  <div className="text-xs text-gray-500">{skuDetails.is_weight_based ? 'Weight' : 'Quantity'}</div>
                  <div className="text-sm font-medium">
                    {skuDetails.is_weight_based ? `${selectedBatch.weight_kilos} kg` : selectedBatch.quantity}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-gray-500">Available</div>
                  <div className="text-sm font-medium">
                    {skuDetails.is_weight_based 
                      ? `${selectedBatch.available_quantity ?? selectedBatch.weight_kilos} kg`
                      : selectedBatch.available_quantity ?? selectedBatch.quantity
                    }
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 rounded-lg p-3 grid grid-cols-2 gap-4">
                <div>
                  <div className="text-xs text-gray-500">Expiry Date</div>
                  <div className="text-sm font-medium">{selectedBatch.expiry_date || 'N/A'}</div>
                </div>
                <div>
                  <div className="text-xs text-gray-500">Date Received</div>
                  <div className="text-sm font-medium">{selectedBatch.date_received}</div>
                </div>
              </div>
              <div className="bg-blue-50 rounded-lg p-3">
                <div className="text-xs text-blue-600">Purchase Cost</div>
                <div className="text-lg font-semibold text-blue-900">
                  CHF {(selectedBatch.purchase_cost ?? 0).toFixed(2)}
                </div>
              </div>
              {selectedBatch.notes && (
                <div className="border-t pt-3 mt-3">
                  <div className="text-xs text-gray-500 mb-1">Notes</div>
                  <div className="text-sm text-gray-700 whitespace-pre-wrap bg-gray-50 p-3 rounded-lg">
                    {selectedBatch.notes}
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

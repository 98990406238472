import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Info } from 'lucide-react';

interface InfoModalProps {
  onClose?: () => void;
}

export function InfoModal({ onClose }: InfoModalProps) {
  return (
    <Dialog onOpenChange={onClose}>
      <DialogTrigger asChild>
        <button className="flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200 text-white hover:bg-[#C3D48A] hover:text-[#304661]">
          <Info className="h-5 w-5 flex-shrink-0" />
        </button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
        <div className="space-y-6 py-6">
          {/* Top Section with Logo and Welcome */}
          <div className="max-w-4xl mx-auto">
            <div className="space-y-6">
              {/* Header Section */}
              <div className="relative">
                <div className="w-full h-32 overflow-hidden rounded-t-lg">
                  <img 
                    src="/welcome.jpg" 
                    alt="Welcome Banner" 
                    className="w-full object-cover -mt-16"
                  />
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-900/80 to-transparent" />
                </div>
                
                <div className="absolute bottom-0 left-0 right-0 p-6 flex items-center">
                  <div className="flex items-center space-x-4">
                    <div className="p-2">
                      <img src="/ims3.png" alt="Chefland IMS Logo" className="w-16 h-auto" />
                    </div>
                    <div className="text-white">
                      <h2 className="text-2xl font-bold">Chefland IMS</h2>
                      <div className="text-blue-100 text-sm">Version 0.85</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Welcome Message */}
              <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold mb-3 text-gray-800">Welcome to Chefland IMS</h3>
                <p className="text-sm text-gray-600 leading-relaxed">
                  A modern inventory management system designed for precision and efficiency in food service operations. 
                  Built with React and Supabase, it offers real-time inventory tracking, batch management, and 
                  invoice generation with a focus on data accuracy and user experience.
                </p>
              </div>
            </div>
          </div>

          {/* Key Features */}
          <div>
            <h3 className="text-xl font-semibold mb-3">Core Features</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <div className="p-3 bg-white rounded-lg border border-gray-200">
                <h4 className="font-semibold mb-1">📊 Dashboard</h4>
                <ul className="space-y-0.5 text-sm text-gray-600">
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Real-time inventory overview</span>
                  </li>
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Expiry monitoring alerts</span>
                  </li>
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Key metrics visualization</span>
                  </li>
                </ul>
              </div>
              <div className="p-3 bg-white rounded-lg border border-gray-200">
                <h4 className="font-semibold mb-1">📦 Master Inventory</h4>
                <ul className="space-y-0.5 text-sm text-gray-600">
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Dual measurement system (weight/quantity)</span>
                  </li>
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>CSV bulk import/update capability</span>
                  </li>
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Real-time stock calculations</span>
                  </li>
                </ul>
              </div>
              <div className="p-3 bg-white rounded-lg border border-gray-200">
                <h4 className="font-semibold mb-1">🏷️ Batch Management</h4>
                <ul className="space-y-0.5 text-sm text-gray-600">
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Multi-warehouse support</span>
                  </li>
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Expiry date monitoring</span>
                  </li>
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Purchase cost tracking</span>
                  </li>
                  <li className="flex">
                    <span className="mr-2">•</span>
                    <span>Balance audit system with discrepancy alerts ⚖️</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Process Flow */}
          <div>
            <h3 className="text-xl font-semibold mb-3">System Architecture</h3>
            <div className="space-y-4">
              {/* Stock Calculations */}
              <div className="bg-white p-4 rounded-lg border border-gray-200">
                <h4 className="font-semibold mb-2">System Architecture</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="p-3 bg-gray-50 rounded-lg">
                    <h5 className="font-medium mb-1">Balance Audit System 🎯</h5>
                    <p className="text-sm text-gray-600 mb-2">The balance audit system maintains data accuracy through:</p>
                    <ul className="space-y-0.5 text-sm text-gray-600">
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Real-time comparison of stored vs. calculated quantities</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Automatic reconciliation detection for new and used batches</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Precise tracking of finalized invoice impacts</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Detailed audit logs with calculation breakdowns</span>
                      </li>
                    </ul>
                  </div>

                  <div className="p-3 bg-gray-50 rounded-lg">
                    <h5 className="font-medium mb-1">Stock Calculation Logic</h5>
                    <div className="space-y-3">
                      <div>
                        <div className="text-sm text-gray-600 mb-1">New Batches:</div>
                        <div className="bg-white p-2 rounded border border-gray-200 font-mono text-sm">
                          available_quantity = original_quantity
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-600 mb-1">Used Batches:</div>
                        <div className="bg-white p-2 rounded border border-gray-200 font-mono text-sm">
                          available = original - sum(finalized_invoice_quantities)
                        </div>
                      </div>
                    </div>
                    <p className="text-sm text-gray-600 mt-2">
                      System maintains both original and available quantities. Only finalized invoices affect stock levels.
                    </p>
                  </div>
                </div>
              </div>

              {/* Data Management */}
              <div className="bg-white p-4 rounded-lg border border-gray-200">
                <h4 className="font-semibold mb-2">Data Management</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="p-3 bg-gray-50 rounded-lg">
                    <h5 className="font-medium mb-1">Master Inventory Import</h5>
                    <ul className="space-y-0.5 text-sm text-gray-600">
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Bulk CSV import with duplicate detection</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Intelligent update/insert handling</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Field-by-field change tracking</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Detailed import summaries</span>
                      </li>
                    </ul>
                  </div>

                  <div className="p-3 bg-gray-50 rounded-lg">
                    <h5 className="font-medium mb-1">Invoice Management</h5>
                    <ul className="space-y-0.5 text-sm text-gray-600">
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Status-based stock impact (draft/final)</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Multi-batch allocation per item</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Automatic stock level updates</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Historical tracking</span>
                      </li>
                    </ul>
                  </div>

                  <div className="p-3 bg-gray-50 rounded-lg">
                    <h5 className="font-medium mb-1">Deletion Order</h5>
                    <ul className="space-y-0.5 text-sm text-gray-600">
                      <li className="flex">
                        <span className="mr-2">1.</span>
                        <span>Delete or void invoices first</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">2.</span>
                        <span>Then delete associated batches</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">3.</span>
                        <span>Finally delete the inventory item</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Technical Features */}
              <div className="bg-white p-4 rounded-lg border border-gray-200">
                <h4 className="font-semibold mb-2">Technical Features</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <h5 className="font-medium mb-1">Frontend</h5>
                    <ul className="space-y-0.5 text-sm text-gray-600">
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>React with TypeScript</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Real-time updates</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Modern UI components</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Responsive design</span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="font-medium mb-1">Backend</h5>
                    <ul className="space-y-0.5 text-sm text-gray-600">
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Supabase database</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Real-time calculations</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Efficient batch operations</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Secure authentication</span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="font-medium mb-1">Authentication System 🔐</h5>
                    <ul className="space-y-0.5 text-sm text-gray-600">
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Single Sign-On with Google</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Role-based access control</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Whitelist-based authorization</span>
                      </li>
                      <li className="flex">
                        <span className="mr-2">•</span>
                        <span>Secure session management</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

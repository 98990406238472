import React from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Eye, Info, TrendingUp, TrendingDown } from "lucide-react";
import { useState } from "react";
import { Batch } from "@/types/batch";
import { supabase } from "@/lib/supabase";
import { useQuery } from "@tanstack/react-query";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface ViewBatchDialogProps {
  batch: Batch;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

interface PriceHistoryProps {
  sku: string;
  currentBatchNumber: string;
}

function PriceHistory({ sku, currentBatchNumber }: PriceHistoryProps) {
  const { data: recentBatches } = useQuery({
    queryKey: ['batch-history', sku],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('batches')
        .select('batch_number, date_received, purchase_cost')
        .eq('sku', sku)
        .order('date_received', { ascending: false })
        .limit(10);

      if (error) throw error;
      return data || [];
    },
  });

  if (!recentBatches?.length) return null;

  // Calculate percentage changes
  const batchesWithChanges = recentBatches.map((batch, index) => {
    let percentChange = null;
    if (index < recentBatches.length - 1) {
      const prevCost = recentBatches[index + 1].purchase_cost;
      const currentCost = batch.purchase_cost;
      if (prevCost !== null && prevCost !== undefined && 
          currentCost !== null && currentCost !== undefined) {
        percentChange = ((currentCost - prevCost) / prevCost) * 100;
      }
    }
    return { ...batch, percentChange };
  });

  return (
    <div className="divide-y divide-gray-100">
      {batchesWithChanges.map((batch) => (
        <div 
          key={batch.batch_number}
          className={cn(
            "flex items-center justify-between px-3 py-2",
            batch.batch_number === currentBatchNumber ? "bg-blue-50" : "hover:bg-gray-50"
          )}
        >
          <div className="flex-1">
            <div className="font-medium">
              {new Date(batch.date_received).toLocaleDateString()}
            </div>
            <div className="text-sm text-gray-500 flex items-center gap-1">
              {batch.batch_number}
              {batch.batch_number === currentBatchNumber && (
                <span className="text-xs bg-blue-100 text-blue-800 px-1.5 py-0.5 rounded-sm">Current</span>
              )}
            </div>
          </div>
          <div className="text-right flex items-center gap-2">
            <div>
              <div className="font-medium">
                {batch.purchase_cost !== null && batch.purchase_cost !== undefined 
                  ? `CHF ${batch.purchase_cost.toFixed(2)}` 
                  : '-'}
              </div>
              {batch.percentChange !== null && (
                <div className={cn(
                  "text-sm flex items-center justify-end gap-0.5",
                  batch.percentChange > 0 ? "text-red-600" : batch.percentChange < 0 ? "text-green-600" : "text-gray-500"
                )}>
                  {batch.percentChange > 0 ? (
                    <TrendingUp className="h-3 w-3" />
                  ) : batch.percentChange < 0 ? (
                    <TrendingDown className="h-3 w-3" />
                  ) : null}
                  {batch.percentChange > 0 ? "+" : ""}
                  {batch.percentChange.toFixed(1)}%
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function ViewBatchDialog({ 
  batch,
  open,
  onOpenChange
}: ViewBatchDialogProps) {
  // Fetch SKU details when dialog is opened
  const { data: skuDetails } = useQuery({
    queryKey: ['sku', batch.sku, open],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('inventory')
        .select('item_name, is_weight_based')
        .eq('sku', batch.sku)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: open,
  });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Batch Details - {batch.batch_number}</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="text-sm font-semibold text-gray-500">SKU</div>
              <div>{batch.sku}</div>
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-500">Product Name</div>
              <div>{skuDetails?.item_name}</div>
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-500">
                {skuDetails?.is_weight_based ? 'Weight' : 'Quantity'}
              </div>
              <div>
                {skuDetails?.is_weight_based ? (
                  <span>{batch.weight_kilos} kg</span>
                ) : (
                  <span>{batch.quantity}</span>
                )}
              </div>
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-500 flex items-center gap-1">
                Available
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Info className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-help" />
                    </TooltipTrigger>
                    <TooltipContent 
                      className="bg-white border border-gray-200 shadow-lg rounded-md p-4"
                      sideOffset={5}
                    >
                      <div className="text-gray-900">
                        <p className="font-medium">This shows the current availability for this specific batch:</p>
                        <ul className="mt-2 space-y-1">
                          {skuDetails?.is_weight_based ? (
                            <>
                              <li>• <strong>Weight</strong>: Original batch weight ({batch.weight_kilos} kg)</li>
                              <li>• <strong>Available</strong>: Remaining weight in this batch ({batch.available_quantity ?? batch.weight_kilos} kg)</li>
                            </>
                          ) : (
                            <>
                              <li>• <strong>Quantity</strong>: Original batch size ({batch.quantity})</li>
                              <li>• <strong>Available</strong>: Remaining items in this batch ({batch.available_quantity ?? batch.quantity})</li>
                            </>
                          )}
                        </ul>
                        <p className="mt-2 text-sm text-gray-600">
                          The difference represents {skuDetails?.is_weight_based ? 'weight' : 'items'} from this batch that have been sold or allocated to pending orders.
                        </p>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div>
                <span>
                  {skuDetails?.is_weight_based 
                    ? `${batch.available_quantity ?? batch.weight_kilos} kg`
                    : batch.available_quantity ?? batch.quantity
                  }
                </span>
              </div>
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-500">Expiry Date</div>
              <div>{batch.expiry_date || 'N/A'}</div>
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-500">Date Received</div>
              <div>{batch.date_received}</div>
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-500">Purchase Cost</div>
              <div>{batch.purchase_cost !== null && batch.purchase_cost !== undefined 
                ? `CHF ${batch.purchase_cost.toFixed(2)}` 
                : '-'}</div>
            </div>
            <div>
              <div className="text-sm font-semibold text-gray-500">Warehouse</div>
              <div>{batch.warehouse}</div>
            </div>
            {batch.notes && (
              <div className="col-span-2">
                <div className="text-sm font-semibold text-gray-500">Notes</div>
                <div className="mt-1 text-gray-700 whitespace-pre-wrap">{batch.notes}</div>
              </div>
            )}
          </div>

          <div className="mt-6 pt-6 border-t border-gray-200">
            <h4 className="text-sm font-semibold text-gray-900 mb-4">Price History</h4>
            <PriceHistory sku={batch.sku} currentBatchNumber={batch.batch_number} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import { UserRole, USER_ROLES } from '@/types/auth';
import { useToast } from "@/components/ui/use-toast";
import { useLocation } from 'react-router-dom';

interface GoogleUser {
  email: string;
  name?: string;
  picture?: string;
}

interface AuthContextType {
  user: GoogleUser | null;
  role: UserRole | null;
  isAdmin: boolean;
  isEditor: boolean;
  isGuest: boolean;
  isInbound: boolean;
  canEdit: boolean;
  canAddBatches: boolean;
  canManageInventory: boolean;
  signInWithGoogle: () => void;
  signOut: () => Promise<void>;
  isInitialized: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Named export for the provider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<GoogleUser | null>(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [role, setRole] = useState<UserRole | null>(() => {
    const savedRole = localStorage.getItem('role');
    return savedRole ? JSON.parse(savedRole) : null;
  });
  const [isInitialized, setIsInitialized] = useState(false);

  const { toast } = useToast();

  const location = useLocation();

  // Function to fetch user info
  const fetchUserInfo = async (accessToken: string) => {
    try {
      const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }
      
      const userInfo = await response.json();
      
      const newUser = {
        email: userInfo.email,
        name: userInfo.name,
        picture: userInfo.picture
      };
      
      setUser(newUser);
      localStorage.setItem('user', JSON.stringify(newUser));

      // Set role based on email mapping
      const assignedRole = USER_ROLES[userInfo.email];
      if (assignedRole) {
        setRole(assignedRole);
        localStorage.setItem('role', JSON.stringify(assignedRole));
      } else {
        console.warn(`Unauthorized access attempt from email: ${userInfo.email}`);
        setRole(null);
        localStorage.removeItem('role');
        // Show unauthorized message
        toast({
          variant: "destructive",
          title: "Access Denied",
          description: "Sorry, you are not authorized to access Chefland IMS. Please contact support.",
        });
        // Immediately sign out unauthorized users
        signOut();
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setUser(null);
      setRole(null);
      localStorage.removeItem('user');
      localStorage.removeItem('role');
    }
  };

  // Handle Google sign in
  const signInWithGoogle = useGoogleLogin({
    onSuccess: async (response: TokenResponse) => {
      await fetchUserInfo(response.access_token);
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setUser(null);
      setRole(null);
      localStorage.removeItem('user');
      localStorage.removeItem('role');
    },
    flow: 'implicit'
  });

  // Sign out
  const signOut = async () => {
    setUser(null);
    setRole(null);
    localStorage.removeItem('user');
    localStorage.removeItem('role');
  };

  // Set initialized after first render
  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const value = {
    user,
    role,
    isAdmin: role === 'admin',
    isEditor: role === 'editor',
    isGuest: role === 'guest',
    isInbound: role === 'inbound',
    canEdit: role === 'admin' || role === 'editor' || (role === 'inbound' && location.pathname.includes('/batches')),
    canAddBatches: role === 'admin' || role === 'editor' || role === 'inbound',
    canManageInventory: role === 'admin' || role === 'editor',
    signInWithGoogle,
    signOut,
    isInitialized
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Named export for the hook
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
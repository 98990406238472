import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import JSZip from "jszip";

export function ExportTablesButton() {
  const { toast } = useToast();

  const handleExport = async () => {
    try {
      // Fetch data from all three tables
      const [inventoryResult, batchesResult, invoicesResult, inventoryItemsResult] = await Promise.all([
        supabase.from('inventory').select('*').order('item_name'),
        supabase.from('batches').select('*').order('batch_number'),
        supabase.from('invoices').select(`
          *,
          invoice_items (
            id,
            quantity,
            weight_kilos,
            measurement_type,
            batch_number
          )
        `).order('date', { ascending: false }),
        supabase.from('inventory').select('sku, item_name')
      ]);

      if (inventoryResult.error) throw inventoryResult.error;
      if (batchesResult.error) throw batchesResult.error;
      if (invoicesResult.error) throw invoicesResult.error;
      if (inventoryItemsResult.error) throw inventoryItemsResult.error;

      const inventory = inventoryResult.data;
      const batches = batchesResult.data;
      const invoices = invoicesResult.data;
      
      // Create a map of SKUs to item names
      const skuToItemName = new Map(
        inventoryItemsResult.data.map(item => [item.sku, item.item_name])
      );

      // Convert inventory to CSV
      const inventoryHeaders = ['sku,item_name,description,vat_rate,is_weight_based'];
      const inventoryRows = inventory.map(item => {
        return [
          item.sku,
          item.item_name,
          item.description || '',
          item.vat_rate,
          item.is_weight_based
        ].map(field => {
          if (typeof field === 'string' && (field.includes(',') || field.includes('"'))) {
            return `"${field.replace(/"/g, '""')}"`;
          }
          return field;
        }).join(',');
      });
      const inventoryCsv = [...inventoryHeaders, ...inventoryRows].join('\n');

      // Convert batches to CSV
      const batchesHeaders = ['batch_number,sku,quantity,weight_kilos,expiry_date,date_received,purchase_cost,warehouse,available_quantity'];
      const batchesRows = batches.map(batch => {
        // Fix warehouse field by decoding HTML entities and using a simple temperature string
        const tempMatch = batch.warehouse.match(/[+-]\d+/);
        const tempValue = tempMatch ? tempMatch[0] : '';
        const warehouseTemp = `(${tempValue} C)`;  // Removed the degree symbol entirely for now
        
        return [
          batch.batch_number,
          batch.sku,
          batch.quantity || '',
          batch.weight_kilos || '',
          batch.expiry_date,
          batch.date_received,
          batch.purchase_cost,
          warehouseTemp,
          batch.available_quantity || ''
        ].map(field => {
          if (typeof field === 'string' && (field.includes(',') || field.includes('"'))) {
            return `"${field.replace(/"/g, '""')}"`;
          }
          return field;
        }).join(',');
      });
      const batchesCsv = [...batchesHeaders, ...batchesRows].join('\n');

      // Convert invoices to CSV
      const invoicesHeaders = ['invoice_number,date,company,notes,status,total_amount,items'];
      const invoicesRows = invoices.map(invoice => {
        // Get unique item names for this invoice
        const itemNames = new Set<string>();
        const quantities: string[] = [];

        invoice.invoice_items?.forEach(item => {
          const itemName = skuToItemName.get(
            batches.find(b => b.batch_number === item.batch_number)?.sku || ''
          );
          if (itemName) {
            itemNames.add(itemName);
          }

          // Add quantity or weight to the quantities array
          if (item.measurement_type === 'weight' && item.weight_kilos) {
            quantities.push(`${item.weight_kilos}kg`);
          } else if (item.quantity) {
            quantities.push(`${item.quantity}`);
          }
        });

        // Join item names and quantities with commas
        const itemsList = Array.from(itemNames).join(', ');
        const totalAmount = quantities.join(', ');

        return [
          invoice.invoice_number,
          invoice.date,
          invoice.company,
          invoice.notes || '',
          invoice.status,
          totalAmount,
          itemsList
        ].map(field => {
          if (typeof field === 'string' && (field.includes(',') || field.includes('"'))) {
            return `"${field.replace(/"/g, '""')}"`;
          }
          return field;
        }).join(',');
      });
      const invoicesCsv = [...invoicesHeaders, ...invoicesRows].join('\n');

      // Create ZIP file
      const zip = new JSZip();
      const currentDate = new Date().toISOString().split('T')[0];
      
      zip.file(`inventory_${currentDate}.csv`, inventoryCsv);
      zip.file(`batches_${currentDate}.csv`, batchesCsv);
      zip.file(`invoices_${currentDate}.csv`, invoicesCsv);

      const zipBlob = await zip.generateAsync({ type: "blob" });
      
      // Trigger download
      const url = window.URL.createObjectURL(zipBlob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `ims_export_${currentDate}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast({
        title: "Export successful",
        description: `Exported all tables to ZIP file`
      });

    } catch (error: any) {
      console.error('Export failed:', error);
      toast({
        title: "Export failed",
        description: error.message,
        variant: "destructive"
      });
    }
  };

  return (
    <Button
      variant="outline"
      size="sm"
      className="gap-2"
      onClick={handleExport}
    >
      <Download className="h-4 w-4" />
      Export Tables
    </Button>
  );
}

import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Form } from "../ui/form";
import { Button } from "../ui/button";
import { Pencil } from "lucide-react";
import { supabase } from "../../lib/supabase";
import { useToast } from "../ui/use-toast";
import { BatchFormFields } from "./BatchFormFields";
import { batchFormSchema, type BatchFormValues } from "../../types/batch";
import { useAuth } from "../../contexts/AuthContext";
import type { Batch } from "../../types/batch";

interface EditBatchDialogProps {
  batch: Batch;
  onBatchUpdated: () => void;
}

export function EditBatchDialog({ batch, onBatchUpdated }: EditBatchDialogProps) {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();
  const { canEdit } = useAuth();

  if (!canEdit) return null;

  const form = useForm<BatchFormValues>({
    resolver: zodResolver(batchFormSchema),
    defaultValues: {
      batch_number: batch.batch_number,
      sku: batch.sku,
      quantity: batch.quantity ?? undefined,
      weight_kilos: batch.weight_kilos ?? undefined,
      expiry_date: batch.expiry_date,
      date_received: batch.date_received,
      purchase_cost: batch.purchase_cost ?? undefined,
      warehouse: batch.warehouse as "(+3°C)" | "(+25°C)" | "(-25°C)" | "Paradiso" | "Consignment",
      notes: batch.notes ?? "",
    },
  });

  const onSubmit = async (values: BatchFormValues) => {
    try {
      const { error } = await supabase
        .from('batches')
        .update({
          ...values,
          available_quantity: values.weight_kilos !== undefined ? values.weight_kilos : values.quantity,
        })
        .eq('batch_number', batch.batch_number);

      if (error) throw error;

      toast({
        title: "Batch updated successfully",
        description: `Batch ${batch.batch_number} has been updated.`
      });

      setOpen(false);
      onBatchUpdated();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error updating batch",
        description: error.message
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon" className="h-8 w-8">
          <Pencil className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-right">Edit Batch</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <BatchFormFields form={form} isEditMode={true} />
            <Button type="submit" className="w-full">Update Batch</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

import React, { useRef, useEffect } from 'react';
import { 
  BookOpen, 
  Box, 
  ClipboardList, 
  BarChart3, 
  Warehouse,
  Users,
  FileSpreadsheet,
  AlertCircle,
  CheckCircle2,
  ArrowRight,
  FileText,
  PackageCheck,
  Receipt,
  ClipboardCheck,
  Info,
  Calendar,
  Scale,
  Package
} from 'lucide-react';

const Guide = () => {
  const inventoryRef = useRef<HTMLDivElement>(null);
  const batchesRef = useRef<HTMLDivElement>(null);
  const invoicesRef = useRef<HTMLDivElement>(null);
  const auditRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="min-h-screen bg-[#FDF7FA] pt-16">
      {/* Hero Section */}
      <div 
        className="relative h-[400px] bg-cover bg-center"
        style={{ backgroundImage: 'url(/welcome.jpg)' }}
      >
        <div className="absolute inset-0 bg-[#304661]/80">
          <div className="container mx-auto px-4 h-full flex flex-col justify-center">
            <div className="max-w-3xl opacity-0 translate-y-4 animate-fade-in-up">
              <img src="/ims_logo2.png" alt="IMS Logo" className="h-16 mb-6" />
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                User Guide
              </h1>
              <p className="text-xl text-[#C3D48A]">
                Everything you need to know about managing your inventory efficiently
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        {/* Quick Navigation */}
        <section className="mb-16">
          <div className="grid md:grid-cols-4 gap-6">
            <QuickNavCard
              icon={Box}
              title="Inventory Management"
              description="Learn how to manage your inventory items"
              color="#99AE4C"
              onClick={() => scrollToSection(inventoryRef)}
            />
            <QuickNavCard
              icon={PackageCheck}
              title="Batch Control"
              description="Handle batches and expiry dates"
              color="#C6D165"
              onClick={() => scrollToSection(batchesRef)}
            />
            <QuickNavCard
              icon={Receipt}
              title="Invoicing"
              description="Create and manage invoices"
              color="#C3D48A"
              onClick={() => scrollToSection(invoicesRef)}
            />
            <QuickNavCard
              icon={ClipboardCheck}
              title="Audit Tools"
              description="Track and reconcile inventory"
              color="#304661"
              onClick={() => scrollToSection(auditRef)}
            />
          </div>
        </section>

        {/* Getting Started */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-[#304661] mb-6">Getting Started</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <h3 className="font-semibold text-[#304661] mb-4">Access Levels</h3>
              <div className="space-y-3">
                <h4 className="font-medium text-[#304661] mb-2">The IMS has three user roles:</h4>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <Users className="h-5 w-5 text-[#99AE4C]" />
                    <span>Guest: View-only access to all data and reports</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <Users className="h-5 w-5 text-[#99AE4C]" />
                    <span>Editor: Full access to create, edit, and delete inventory data</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <Users className="h-5 w-5 text-[#99AE4C]" />
                    <span>Admin: Same permissions as Editor (admin panel coming soon)</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <h3 className="font-semibold text-[#304661] mb-4">First Login</h3>
              <div className="space-y-3">
                <ol className="list-decimal pl-5 space-y-2">
                  <li>Request access from system administrator</li>
                  <li>Log in with your Google account</li>
                  <li>Your role will be automatically assigned</li>
                  <li>If unauthorized, contact support</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        {/* Detailed Guides */}
        <section className="space-y-8">
          <div ref={inventoryRef}>
            <DetailedGuideSection
              title="Managing Inventory"
              icon={Box}
              content={<InventoryGuide />}
            />
          </div>
          <div ref={batchesRef}>
            <DetailedGuideSection
              title="Working with Batches"
              icon={PackageCheck}
              content={<BatchGuide />}
            />
          </div>
          <div ref={invoicesRef}>
            <DetailedGuideSection
              title="Invoice Operations"
              icon={Receipt}
              content={<InvoiceGuide />}
            />
          </div>
          <div ref={auditRef}>
            <DetailedGuideSection
              title="Audit & Reconciliation"
              icon={ClipboardCheck}
              content={<AuditGuide />}
            />
          </div>
        </section>

        {/* Dashboard Overview */}
        <section className="mt-8 bg-white rounded-2xl p-8 shadow-lg">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-3xl font-bold text-[#304661] mb-6">Dashboard Overview</h2>
              <div className="space-y-4">
                <div className="flex items-center gap-4 text-lg text-gray-700">
                  <BarChart3 className="h-6 w-6 text-[#99AE4C]" />
                  Total inventory value
                </div>
                <div className="flex items-center gap-4 text-lg text-gray-700">
                  <Warehouse className="h-6 w-6 text-[#99AE4C]" />
                  Value distribution by warehouse
                </div>
                <div className="flex items-center gap-4 text-lg text-gray-700">
                  <AlertCircle className="h-6 w-6 text-[#99AE4C]" />
                  Expiry alerts (30/60/90 days)
                </div>
                <div className="flex items-center gap-4 text-lg text-gray-700">
                  <BarChart3 className="h-6 w-6 text-[#99AE4C]" />
                  Most sold products
                </div>
              </div>
            </div>
            <div className="rounded-lg overflow-hidden shadow-lg">
              <img src="/dash.jpg" alt="Dashboard" className="w-full" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const QuickNavCard = ({ icon: Icon, title, description, color, onClick }: any) => (
  <div
    onClick={onClick}
    className="bg-white p-6 rounded-xl shadow-lg cursor-pointer transition-transform duration-200 hover:scale-105"
    style={{ borderLeft: `4px solid ${color}` }}
  >
    <Icon className="h-8 w-8 mb-4" style={{ color }} />
    <h3 className="font-semibold text-[#304661] mb-2">{title}</h3>
    <p className="text-sm text-gray-600">{description}</p>
  </div>
);

const AccessLevelCard = ({ title, features }: any) => (
  <div className="bg-[#FDF7FA] p-6 rounded-lg">
    <h4 className="font-semibold text-[#304661] mb-3">{title}</h4>
    <ul className="space-y-2">
      {features.map((feature: string, index: number) => (
        <li key={index} className="flex items-center text-sm text-gray-600">
          <CheckCircle2 className="h-4 w-4 text-[#99AE4C] mr-2" />
          {feature}
        </li>
      ))}
    </ul>
  </div>
);

const DetailedGuideSection = ({ title, icon: Icon, content }: any) => (
  <div className="bg-white rounded-2xl p-8 shadow-lg">
    <div className="flex items-center gap-3 mb-6">
      <div className="p-2 bg-[#99AE4C]/10 rounded-lg">
        <Icon className="h-6 w-6 text-[#99AE4C]" />
      </div>
      <h2 className="text-2xl font-bold text-[#304661]">{title}</h2>
    </div>
    {content}
  </div>
);

const InventoryGuide = () => (
  <div className="space-y-6">
    <div>
      <h3 className="text-xl font-semibold text-[#304661] mb-4">Adding Items Manually</h3>
      <ol className="list-decimal pl-5 space-y-2">
        <li>Navigate to Master Inventory</li>
        <li>Click "Add Item" button</li>
        <li>
          Complete required fields:
          <div className="bg-[#FDF7FA] p-4 rounded-lg mt-2 font-mono text-sm">
            SKU: Unique identifier<br />
            Item Name: Full product name<br />
            Description: Product category<br />
            VAT Rate: 2.6% (food) or 8.1% (non-food)<br />
            Is Weight Based: Toggle for weight/quantity tracking
          </div>
        </li>
        <li>
          Choose tracking method:
          <ul className="pl-5 mt-2 space-y-2">
            <li className="flex items-start gap-2">
              <Scale className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Weight Based: Track items by kilos (e.g., cheese, meat)</span>
            </li>
            <li className="flex items-start gap-2">
              <Package className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Quantity Based: Track items by units (e.g., bottles, boxes)</span>
            </li>
          </ul>
        </li>
      </ol>
    </div>

    <div>
      <h3 className="text-xl font-semibold text-[#304661] mb-4">Bulk Import via CSV</h3>
      <div className="space-y-4">
        <div>
          <h4 className="font-medium text-[#304661] mb-2">Required Format</h4>
          <div className="bg-[#FDF7FA] p-4 rounded-lg font-mono text-sm">
            sku,item_name,description,vat_rate,is_weight_based
          </div>
        </div>
        
        <div>
          <h4 className="font-medium text-[#304661] mb-2">Example CSV</h4>
          <div className="rounded-lg overflow-hidden shadow-md">
            <img src="/sample_csv2.png" alt="Sample CSV Format" className="w-full" />
          </div>
        </div>
        
        <div>
          <h4 className="font-medium text-[#304661] mb-2">Data Handling Rules</h4>
          <ul className="space-y-2">
            <li className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>New SKUs will be added as new entries</span>
            </li>
            <li className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Existing SKUs will be updated if there are changes</span>
            </li>
            <li className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Items without changes will be skipped</span>
            </li>
          </ul>
        </div>

        <div>
          <h4 className="font-medium text-[#304661] mb-2">Preview Changes</h4>
          <ul className="space-y-2">
            <li className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full bg-green-500"></div>
              <span>Green: New items to be added</span>
            </li>
            <li className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full bg-blue-500"></div>
              <span>Blue: Updates to existing items</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div>
      <h3 className="text-xl font-semibold text-[#304661] mb-4">Exporting Data</h3>
      <ul className="space-y-2">
        <li>Use the Export button to download:</li>
        <ul className="list-disc pl-5 mt-2">
          <li>Full inventory list</li>
          <li>Current batch status</li>
          <li>Invoice history</li>
        </ul>
        <li>Files download as CSV with current date</li>
      </ul>
    </div>
  </div>
);

const BatchGuide = () => (
  <div className="space-y-6">
    <div>
      <h3 className="text-xl font-semibold text-[#304661] mb-4">Managing Batches</h3>
      <div className="space-y-4">
        <div>
          <h4 className="font-medium text-[#304661] mb-2">Available Locations</h4>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h5 className="font-medium mb-2">Temperature Controlled</h5>
              <ul className="space-y-1">
                <li>Main Storage</li>
                <li>Cold Storage</li>
                <li>Ambient</li>
              </ul>
            </div>
            <div>
              <h5 className="font-medium mb-2">Additional</h5>
              <ul className="space-y-1">
                <li>Paradiso</li>
                <li>Consignment</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <h4 className="font-medium text-[#304661] mb-2">Batch Information</h4>
          <ul className="space-y-2">
            <li className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Each batch must have a unique batch number</span>
            </li>
            <li className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Expiry date is required for all batches</span>
            </li>
            <li className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Location must be selected from available options</span>
            </li>
            <li className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
              <span>Quantity or weight must match item's tracking method</span>
            </li>
          </ul>
        </div>

        <div>
          <h4 className="font-medium text-[#304661] mb-2">Expiry Calendar</h4>
          <div className="space-y-4">
            <p>Access the expiry calendar at <code>/expiry-calendar</code> to track batch expiration dates.</p>
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <h5 className="font-medium mb-2">Features</h5>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <Calendar className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
                    <span>View all batches expiring on specific dates</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <BarChart3 className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
                    <span>See batch value, quantity, and location at a glance</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <AlertCircle className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
                    <span>Plan ahead with clear visibility of upcoming expirations</span>
                  </li>
                </ul>
              </div>
              <div>
                <h5 className="font-medium mb-2">Batch Details</h5>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <Package className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
                    <span>Item name and SKU</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <Scale className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
                    <span>Available quantity/weight</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <Warehouse className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
                    <span>Storage location</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const InvoiceGuide = () => (
  <div className="space-y-6">
    <div>
      <h3 className="text-xl font-semibold text-[#304661] mb-4">Creating Invoices</h3>
      <ol className="list-decimal pl-5 space-y-2">
        <li>Click "New Invoice"</li>
        <li>
          Enter header information:
          <div className="bg-[#FDF7FA] p-4 rounded-lg mt-2 font-mono text-sm">
            Company: Select from list<br />
            Date: Choose current or future<br />
            Notes: Optional details
          </div>
        </li>
        <li>
          Add items:
          <ul className="list-disc pl-5 mt-2">
            <li>Search by SKU or name</li>
            <li>Select batch(es)</li>
            <li>Enter quantity</li>
            <li>System validates availability</li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
);

const AuditGuide = () => (
  <div className="space-y-6">
    <div>
      <h3 className="text-xl font-semibold text-[#304661] mb-4">Audit Process</h3>
      <ul className="space-y-3">
        <li className="flex items-start gap-2">
          <Info className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
          <span>System automatically tracks all inventory movements</span>
        </li>
        <li className="flex items-start gap-2">
          <Info className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
          <span>Batch quantities are updated in real-time</span>
        </li>
        <li className="flex items-start gap-2">
          <Info className="h-5 w-5 text-[#99AE4C] mt-0.5 flex-shrink-0" />
          <span>All changes are logged with timestamp and user</span>
        </li>
      </ul>
    </div>

    <div>
      <h3 className="text-xl font-semibold text-[#304661] mb-4">Reconciliation Tools</h3>
      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <h4 className="font-medium text-[#304661] mb-2">Available Reports</h4>
          <ul className="space-y-2">
            <li>Stock movement history</li>
            <li>Batch tracking</li>
            <li>User activity logs</li>
          </ul>
        </div>
        <div>
          <h4 className="font-medium text-[#304661] mb-2">Best Practices</h4>
          <ul className="space-y-2">
            <li>Regular inventory checks</li>
            <li>Batch reconciliation</li>
            <li>Activity review</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default Guide;